<template>
  <div class="vertical-layout h-100" :class="[layoutClasses]" :data-col="isNavMenuHidden ? '1-column' : null">
    <!-- Navbar -->
    <b-navbar :toggleable="false" :variant="navbarBackgroundColor"
      class="header-navbar navbar navbar-shadow align-items-center" :class="[navbarTypeClass]">
      <slot name="navbar" :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :navbarBackgroundColor="navbarBackgroundColor"
        :navbarTypeClass="[...navbarTypeClass, 'header-navbar navbar navbar-shadow align-items-center']">
        <app-navbar-vertical-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" />
      </slot>
    </b-navbar>
    <!--/ Navbar -->

    <!-- Vertical Nav Menu -->
    <vertical-nav-menu v-if="!isNavMenuHidden" :is-vertical-menu-active="isVerticalMenuActive"
      :toggle-vertical-menu-active="toggleVerticalMenuActive">
      <template #header="slotProps">
        <slot name="vertical-menu-header" v-bind="slotProps" />
      </template>
    </vertical-nav-menu>
    <!-- /Vertical Nav Menu -->

    <!-- Vertical Nav Menu Overlay -->
    <div class="sidenav-overlay" :class="overlayClasses" @click="isVerticalMenuActive = false" />
    <!-- /Vertical Nav Menu Overlay -->

    <!-- Content -->

    <!-- CONTENT TYPE: Left -->
    <transition :name="routerTransition" mode="out-in">
      <component :is="layoutContentRenderer"
        :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null">
        <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </component>
    </transition>
    <!--/ Content -->
    
    <div class="buy-now" v-if="roleName == 'parent' || roleName == 'student'">
      <validation-observer ref="formSuggest">
        <b-modal ref="form" cancel-title="kembali" centered title="Kritik & Saran">
          <b-form>
            <b-form-group label="Nama Pelapor" label-for="h-place" label-cols-md="2">
              <validation-provider #default="{ errors }" name="Nama Pelapor" rules="required">
                <b-form-input id="h-place" placeholder="Perihal" :state="errors.length > 0 ? false : null"
                  :value="account" readonly />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="2" label="Ditujukan">
              <validation-provider #default="{ errors }" name="Ditujukan" rules="required">
                <v-select v-model="model.inputTarget" :options="targets" :reduce="(target) => target.id"
                  placeholder="Ditujukan" label="name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Tipe Masukan" label-cols-md="2">
              <validation-provider #default="{ errors }" name="Tipe Masukan" rules="required">
                <b-form-radio-group v-model="model.type" :options="options" class="demo-inline-spacing"
                  name="radio-inline" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <validation-provider #default="{ errors }" name="Kritik & Saran" rules="required">
              <b-form-textarea id="textarea-default" v-model="model.value" placeholder="Kritik & Saran" rows="3" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form>
          <validation-provider #default="{ errors }" name="Captcha" rules="required">
            <vue-recaptcha class="mt-2" v-model="model.recaptchaToken" @verify="verifyMethod" sitekey="6LdcOAAjAAAAABu6SsX0nShRxYOsSLUL3ZMkOI3b" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <template #modal-footer>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary"
              class="mt-3 float-right" @click.prevent="submit">
              Simpan
            </b-button>
          </template>
        </b-modal>
      </validation-observer>

      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="lg" variant="info" class="btn-icon rounded-circle"
        @click="showModal()">
        <feather-icon icon="MessageCircleIcon" />
      </b-button>
    </div>

    <!-- Footer -->
    <footer class="footer footer-light" :class="[footerTypeClass]">
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>
    <!-- /Footer -->

    <slot name="customizer" />
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import AppNavbarVerticalLayout from '@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BNavbar, BButton, BModal, BFormGroup, BFormInput, BFormTextarea, BFormRadioGroup, BFormSelect, BForm } from 'bootstrap-vue'
import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import useVerticalLayout from './useVerticalLayout'
import mixinVerticalLayout from './mixinVerticalLayout'
import Ripple from 'vue-ripple-directive'
import vSelect from "vue-select";
import { VueRecaptcha } from 'vue-recaptcha';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    // AppBreadcrumb,
    AppNavbarVerticalLayout,
    AppFooter,
    VerticalNavMenu,
    BNavbar,
    LayoutContentRendererLeftDetached,
    LayoutContentRendererLeft,
    LayoutContentRendererDefault,
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormTextarea,
    BFormRadioGroup,
    BFormSelect,
    BForm,
    VueRecaptcha,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [mixinVerticalLayout],
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
      return 'layout-content-renderer-default'
    },
  },
  setup() {
    const {
      routerTransition, navbarBackgroundColor, navbarType, footerType, isNavMenuHidden,
    } = useAppConfig()

    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      navbarTypeClass,
      footerTypeClass,
    } = useVerticalLayout(navbarType, footerType)

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      overlayClasses,
      layoutClasses,
      navbarTypeClass,
      footerTypeClass,

      // App Config
      routerTransition,
      navbarBackgroundColor,
      isNavMenuHidden,
    }
  },
  data() {
    return {
      options: [
        { text: 'Komplain', value: 'komplain' },
        { text: 'Masukan', value: 'masukan' },
      ],
      targets: [],
      account: null,
      model: {
        account: null,
        inputTarget: null,
        type: null,
        recaptchaToken: null,
        value: null
      },
      roleName: null
    }
  },
  mounted() {
    let account = JSON.parse(localStorage.getItem('account'))
    this.roleName = account.role?.name;
    if(this.roleName == 'parent' || this.roleName == 'student'){
      this.getdropdown();
      this.account = account.name
      this.model.account = account.id
    }
  },
  methods: {
    showModal() {
      this.$refs["form"].show();
    },
    getdropdown() {
      this.$axios.get('/input-target/list').then((res) => {
        this.targets = res.data.data;
      });
    },
    verifyMethod(response) {
      this.model.recaptchaToken = response;
    },
    submit() {
      this.$refs.formSuggest.validate().then(success => {
        if (success) {
          this.$axios.post('input', this.model).then((res) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Pemberitahuan',
                icon: 'BellIcon',
                text: 'Kritik & Saran Berhasil Di Kirim',
                variant: 'success',
              },
            })

            this.model = {
              account: null,
              inputTarget: null,
              type: null,
              recaptchaToken: null,
              value: null
            }

            this.$refs["form"].hide();
          });
        }
      })
    }

  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
